<script>
import { mapMutations } from 'vuex'
import { $post } from '../http/index'
export default {
	data() {
		return {
			success: false,
			form: {
				name: '',
				email: '',
				phone: '',
				message: '',
			}
		}
	},

	computed: {

		checkForm() {
			let valid = true
			for (const key in this.form) {
				if (!this.form[key]) {
					valid = false
				}
			}

			return valid
		},

	},

	methods: {
		async submit() {
			let $api = $post;
			this.addLoader('Enviando mesaje')
			try {
				let res = await $api.contact(this.form)
				this.removeLoader()
				this.success = true
			} catch (error) {
				let $error = error
				console.log($error)
				this.removeLoader()
			}
		},
		...mapMutations({
			addLoader: 'addLoader',
			removeLoader: 'romoveLoader',
		})
	}
}
</script>
<template>
	<div class="container  pt-5">
		<div v-if="success">
			<div class="d-flex  justify-content-center flex-wrap px-2 px-xl-5 py-5">
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 pt-5">

					<div class="text-center">
						<h2 class="title">Gracias por tu mensaje</h2>

						<div class="col-md-8 mx-auto mt-5">
							<router-link :to="{ name: 'HomeIndex' }"
								class="btn btn--block btn--primary">
								Hacer un pedido
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<h1 class="title text-center mb-5">Para sugerencias, negocio o atención al cliente</h1>
			<div class="d-flex flex-wrap mt-4">
				<div class="col-12 col-md-10 col-lg-8 col-xl-5 mx-auto">
					<div class="d-flex flex-wrap row-form mt-4">
						<div class="col-12 p-2">
							<label class="input">
								<span class="input__label">Nombre</span>
								<input class="input__control"
									type="text"
									v-model="form.name">
							</label>
						</div>
						<div class="col-12 col-md-6 p-2">
							<label class="input">
								<span class="input__label">Correo</span>
								<input class="input__control"
									type="text"
									v-model="form.email">
							</label>
						</div>
						<div class="col-12 col-md-6 p-2">
							<label class="input">
								<span class="input__label">Celular</span>
								<input class="input__control"
									type="text"
									v-model="form.phone"
									v-mask="'####-####'">
							</label>
						</div>
						<div class="col-12 p-2">
							<label class="input">
								<span class="input__label">Mensaje</span>
								<textarea class="input__control"
									rows="10"
									type="text"
									v-model="form.message">
									</textarea>
							</label>
						</div>
						<div class="col-8 mx-auto mt-5 pb-5"
							>
							<button class="btn btn--primary btn--bold btn--block"
								:disabled="!checkForm"
								@click="submit">Enviar</button>
							<p v-if="!checkForm"
								class="text-danger texte-center small">*Es necesario llenar todos los campos</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
